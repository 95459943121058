import {WithBottomBar} from "./BottomBar";
import {Paper, ScrollArea, Space, Text, Button, Chip, Avatar, Modal, Textarea} from "@mantine/core";
import {
    aggregate,
    get_me,
    get_schedules,
    get_top_level_threads,
    get_top_mentors,
    get_video_chat_url,
    post_thread
} from "../api/api";
import {storage_get_me} from "../api/storage";
import {useState, useEffect} from "react";
import {slug_to_name} from "../subjects";
import {ViewSchedule} from "./ViewSchedule";
import {IconArrowRight, IconChevronRight, IconMessagePlus} from "@tabler/icons";
import {useNavigate} from "react-router-dom";
import {MentorCheckNotification} from "./MentorCheckNotification";
import {get_schedules_by_mentor, get_schedules_by_user} from "../api/new_mentor_scheduling";
import Carousel, {CarouselItem} from "./Carousel";

function Thread({thread}) {
    const nav = useNavigate();

    const navToThread = () => {
        nav(`/thread/${thread.id}`)
    }
    return (
        <Paper shadow="lg" p="md" w={"60vw"}>
            <div className={"vertical array gaped-1-4 justified-between"} style={{height: "100%"}}>
                <div>
                    <div className={"horizontal array justified-between"}>
                        <div>
                            <Avatar size={"sm"} radius="xl"
                                    src={`https://avatars.dicebear.com/api/open-peeps/${thread.from.username}.svg`}/>
                            <span><b>{thread.from.name}</b></span>
                        </div>
                        <Button onClick={navToThread} variant={"subtle"} ><IconChevronRight color={"gray"}/></Button>
                    </div>
                    <div>
                        {thread.message}
                    </div>
                </div>
                <div className={"horizontal-reverse array"}>
                    <span className={"label"}>{(new Date(thread.posted * 1000)).toLocaleTimeString()}</span>
                </div>
            </div>
        </Paper>
    );
}

function MeetingCard({schedule}) {
    console.log(schedule)
    const navigateToVideoChat = () => {
        const me = get_me()
        window.location.href = get_video_chat_url(me.username, schedule.id)
    }

    return (
        <Paper shadow="lg" p="md">
            <div className={"horizontal array gaped justified-between"}>
                <Avatar size={"lg"} radius="xl"
                        src={`https://avatars.dicebear.com/api/open-peeps/${schedule.mentor.username}.svg`}/>
                <div className={"vertical array gaped-1-3"}>
                    <span>Meeting with <b>{schedule.mentor.name}</b></span>

                    <span className={"sub-text"}>{slug_to_name(schedule.subject)} at <span
                        className={"bold"}>{(new Date(schedule.schedule[0] * 1000)).toLocaleTimeString()}</span></span>
                </div>
                <Button variant={"subtle"} onClick={navigateToVideoChat}><IconChevronRight color={"gray"}/></Button>
            </div>
        </Paper>
    );
}

export function get_most_recent_date(dates) {
    let returnval = dates.reduce((a, v)=> {
        if ((new Date()) / 1000 > v) {
            return a
        }
        return v
    }, null)

    if (!returnval) {
        return null
    }

    return new Date(returnval * 1000)
}

export function Home() {
    let me = storage_get_me()
    let [schedules, setSchedules] = useState([]);
    let [upNext, setUpNext] = useState();
    let [threads, setThreads] = useState([]);
    let [topMentors, setTopMentors] = useState([]);

    let [refresh, setRefresh] = useState(0);

    let [threadModal, setThreadModal] = useState(false);
    let [newThreadMessage, setNewThreadMessage] = useState("");
    let [viewedIntro, setViewedIntro] = useState(localStorage.getItem("viewed-intro") || false);
    let [topSubjects, setTopSubjects] = useState([]);

    const setIntroViewed = () => {
        localStorage.setItem("viewed-intro", "true")
        setViewedIntro("true");
    }

    let nav = useNavigate();

    useEffect(() => {
        aggregate("search-metrics", [
            {
                '$sort': {
                    'views': -1
                }
            }, {
                '$limit': 4
            }, {
                '$project': {_id: 0}
            }
        ]).then(r => {
            console.log("Top Subjects", r)
            setTopSubjects(r)
        })
    }, [])

    useEffect(() => {
        (async () => {
            const todayTs = new Date((new Date()).toDateString()) / 1000

            let fn = me.type === "mentor" ? get_schedules_by_mentor : get_schedules_by_user
            fn(me.username).then(r => {
                console.log(r)
                if(r.length === 0) {
                    return
                }
                if (me.type !== "mentor") {
                    r = r.map(v => v.schedule)
                }
                let transformed_schedules = r.reduce((acc, val) => {
                    console.log("Transformed Schedule Val", val)
                    if (!val.schedule) {
                        return acc
                    }
                    let dates = val.schedule.map(sched => {
                        return {...val, "date": sched}
                    })
                    dates.map(date => {
                        if (!acc[date.date]) {
                            acc[date.date] = []
                        }
                        acc[date.date].push(date)
                    })
                    return acc
                }, {})

                console.log("Schedules", transformed_schedules)
                let x = Object.keys(transformed_schedules).filter(v => ((new Date()) / 1000) - 86000 < Number.parseInt(v) && ((new Date()) / 1000) + 86000 > Number.parseInt(v) )
                if (x.length > 0) {
                    setSchedules(transformed_schedules[x[0]] || [])
                }
                let nxt = null
                Object.values(transformed_schedules).forEach(v => {
                    if (!!nxt) {
                        return
                    }
                    v.forEach(d => {
                        console.log("Processing", d, get_most_recent_date(d.schedule))
                        if (!!get_most_recent_date(d.schedule)) {
                            nxt = d
                        }
                    })
                })
                // const nxt = Object.values(transformed_schedules)[0][0]
                console.log("Up next", nxt)
                setUpNext(nxt)
            }).catch(e => {
                console.log(e)
            })

            let t = await get_top_level_threads();
            console.log("Threads", t)
            setThreads(t)

            let topMentors = await get_top_mentors();
            console.log("Top Mentors", topMentors)
            setTopMentors(topMentors)
        })()
    }, [refresh])

    const newPost = async () => {
        await post_thread(me.username, newThreadMessage);
        setNewThreadMessage("")
        setThreadModal(false)
        setRefresh(Math.random())
    }

    const navigateToProfile = (person) => {
        nav(`/profile/mentor/${person.username}`)
    }

    const navigateToSubject = (subject) => {
        nav(`/looking-for/mentor/${subject}`)
    }


    if (!viewedIntro) {
        return (
                <Carousel>
                    <CarouselItem>
                        <img src={"/assets/1.png"} className={"responsive-img"}/>
                    </CarouselItem>
                    <CarouselItem>
                        <img src={"/assets/2.png"} className={"responsive-img"}/>
                    </CarouselItem>
                    <CarouselItem>
                        <img src={"/assets/3.png"} className={"responsive-img"}/>
                    </CarouselItem>
                    <CarouselItem>
                        <div className={"vertical array carousel-centered"}>
                            <h2>
                                You're all set!
                            </h2>
                            <Button onClick={setIntroViewed}>
                                Continue to Stuney
                            </Button>
                        </div>
                    </CarouselItem>
                </Carousel>
        )
    }

    return (
        <div className="app">
            <Modal
                opened={threadModal}
                onClose={() => setThreadModal(false)}
                title="New Thread"
            >
                <div className={"vertical array gaped"}>
                    <Textarea value={newThreadMessage} onChange={(event) => setNewThreadMessage(event.currentTarget.value)}/>
                    <Button onClick={newPost}>Post</Button>
                </div>
            </Modal>
            <span style={{fontWeight: "bold", fontSize: "2em"}}>Home</span>
            <WithBottomBar active={"home"}>
                <ScrollArea style={{height: "83vh"}}>

                    <div className={"vertical array padded-1-2"}>
                        <MentorCheckNotification/>

                        <div className={"horizontal array justified-between"} style={{alignItems: 'center'}}>
                            <h2>Hot Topics</h2>
                        </div>
                        <ScrollArea w={"95vw"}>
                            <div className={"horizontal array gaped-1-2"}>
                                {
                                    topSubjects.map(t => <Paper shadow="lg" p="md" w={"40vw"}>
                                        <div onClick={() => navigateToSubject(t.subject)} className={"vertical array gaped-1-4 justified-between"} style={{height: "100%"}}>
                                            <div>
                                                <span><b>{slug_to_name(t.subject)}</b></span>
                                            </div>
                                        </div>
                                    </Paper>)
                                }
                            </div>
                        </ScrollArea>

                        <div className={"horizontal array justified-between"} style={{alignItems: 'center'}}>
                            <h2>Top Mentors</h2>
                        </div>
                        <ScrollArea w={"95vw"}>
                            <div className={"horizontal array gaped-1-2"}>
                                {
                                    topMentors.length !== 0 && topMentors.map(t => <Paper shadow="lg" p="md" w={"40vw"}>
                                        <div onClick={() => navigateToProfile(t.mentor)} className={"vertical array gaped-1-4 justified-between"} style={{height: "100%"}}>
                                            <div>
                                                <div className={"horizontal array justified-between"}>
                                                    <Avatar size={"sm"} radius="xl"
                                                        src={`https://avatars.dicebear.com/api/open-peeps/${t.mentor.username}.svg`}/>
                                                    <Chip checked={false} disabled={true}>{t.count} ✨</Chip>
                                                </div>
                                                <span><b>{t.mentor.name}</b></span>
                                            </div>
                                        </div>
                                    </Paper>)
                                }
                            </div>
                        </ScrollArea>

                        <div className={"horizontal array justified-between"} style={{alignItems: 'center'}}>
                            <h2>Newest Posts</h2>
                            <Button onClick={() => setThreadModal(true)} variant={"subtle"}><IconMessagePlus color={"gray"}/></Button>
                        </div>
                        <ScrollArea w={"95vw"}>
                            <div className={"horizontal array gaped-1-2"}>
                                {
                                    threads.length !== 0 && threads.map(t => <Thread thread={t}/>)
                                }
                            </div>
                        </ScrollArea>


                        <h2>Today, {(new Date()).toDateString()}</h2>
                        <div className={"vertical array gaped-1-2"}>
                        {
                            schedules.length === 0 ?
                                <Paper shadow="xs" p="md">
                                    <Chip>{(new Date()).toDateString()}</Chip>
                                    <h3>Nothing lined up for today!</h3>
                                </Paper>
                                : schedules.map(v => {
                                return <MeetingCard schedule={v}/>
                            })
                        }
                        </div>
                        {
                            schedules.length === 0 && <>
                                <h2>Up Next</h2>
                                {
                                    upNext && <Paper shadow="xs" p="md">
                                        <Chip>{get_most_recent_date(upNext.schedule).toDateString()}</Chip>
                                        <h3>Meeting with <b>{upNext.mentor.name}</b> on {slug_to_name(upNext.subject)}</h3>
                                        {/*<ViewSchedule request={upNext} student={upNext.user} me={me}/>*/}
                                    </Paper>
                                }
                            </>
                        }
                    </div>

                </ScrollArea>
            </WithBottomBar>
        </div>
    )
}