import {useEffect, useRef, useState} from "react";
import {showNotification} from "@mantine/notifications";
import {Accordion, Alert, Button, Divider, FileInput, Loader, Radio, RadioGroup, Space, TextInput} from "@mantine/core";
import {AlertCircle} from "tabler-icons-react";
import {TopBar} from "./TopBar";
import {API_URL} from "../config";
import {useNavigate} from "react-router-dom";
import {upload_documents} from "../api/api";

export function Register() {
    localStorage.clear()
    const [userType, setUserType] = useState("user")
    const [message, setMessage] = useState("")
    const [newUser, setNewUser] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [newName, setNewName] = useState("")
    const [newEmail, setNewEmail] = useState("")
    const [newUniversity, setNewUniversity] = useState("")

    const [requireDocuments, setRequireDocuments] = useState(false);
    const [disable, setDisable] = useState(true);
    const [uploading, setUploading] = useState(false);
    const [error, setError] = useState("");

    const fileUploadRef = useRef();
    const nav = useNavigate();

    useEffect(() => {
        if(localStorage.getItem("mentor:ongoing:register") === "true") {
            changeUser("mentor")
        }
    }, [])

    const changeUser = (nUserType) => {
        setMessage("")
        if (nUserType === "mentor") {
            setMessage("Mentors require additional verification to start mentoring.")
        }
        setUserType(nUserType)
    }

    const sign_in = (existing_user_login, existing_user_password) => {
        fetch(`${API_URL}/login?username=${existing_user_login}&password=${existing_user_password}`).then(r => r.json()).then(val => {
            if (val.error) {
                showNotification({
                    title: 'Error',
                    message: val.error,
                })
                return
            }
            showNotification({
                title: 'Logged in',
                message: "Logged in successful",
            })
            localStorage.setItem("token", val.token)
            localStorage.setItem("username", existing_user_login)
            fetch(`${API_URL}/user/${existing_user_login}`).then(r => r.json()).then(v => {
                localStorage.setItem("me", JSON.stringify(v))
                nav("/home")
            })
        })
    }

    const register = () => {
        setMessage("")

        if(!newEmail.includes(".edu")) {
            setMessage("Email should be from an educational institution.")
            return
        }

        if(!(newUser && newPassword && newName && newEmail && newUniversity)) {
            setMessage("Some fields are empty.")
            return
        }

        try {
            console.log("Posting")
            fetch(`${API_URL}/register`, {
                method: "POST",
                body: JSON.stringify(
                    {
                        "username": newUser ? newUser : null,
                        "password": newPassword ? newPassword : null,
                        "email": newEmail ? newEmail : null,
                        "name": newName ? newName : null,
                        "type": userType ? userType : null,
                        "university": newUniversity ? newUniversity : null
                    }
                ),
                headers: {
                    "content-type": "application/json"
                }
            }).then(r => {
                console.log(r)
                if (r.status === 422) {
                    showNotification({
                        title: 'Registration Failed',
                        message: `Some fields are empty`,
                    })
                }
                return r.json()
            }).then(data => {
                if (data.ok) {
                    showNotification({
                        title: 'Registration',
                        message: "User created!",
                    })
                    sign_in(newUser, newPassword)
                }
                if (data.error) {
                    showNotification({
                        title: 'Error',
                        message: data.error
                    })
                }
            })
        } catch (e) {
            console.log(e);
            showNotification({
                title: 'Registration Failed',
                message: `${e}`,
            })
        }

    }

    function blobToBase64(blob) {
        return new Promise((resolve, _) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
        });
    }

    const uploadFiles = async (e) => {
        setDisable(true)
        console.log(e)
        const bin = await blobToBase64(e)
        await upload_documents(newUser, e.name, bin)
        setDisable(false)
    }

    if (requireDocuments) {
        return (<div className={"app"}>
            <span className={"header"}>Awesome, let's sign in</span>
            <Space h={"xl"}/>
            <Divider/>
            {
                message && <Alert icon={<AlertCircle size={16}/>}>
                    {message}
                </Alert>
            }
            {
                uploading ? <div className={"horizontal array justified-evenly"}>
                        <Loader/>
                    </div>
                    : <>
                    <FileInput ref={fileUploadRef} placeholder="Pick file" label="Supporting Document" onChange={uploadFiles}/>
                    <Space h={"md"}/>
                    <Button onClick={register} disabled={disable}>Register</Button>
                </>
            }

        </div>)
    }

    return (
        <div className={"app"}>
            <span className={"header"}>Awesome, let's sign in</span>
            <Space h={"xl"}/>
            <Divider/>
            {
                message && <Alert icon={<AlertCircle size={16}/>}>
                    {message}
                </Alert>
            }
            <Space h={"md"}/>
            <Radio.Group label={"I'm registering as"} value={userType} defaultValue={'student'} onChange={changeUser}>
                <Radio label={"Mentor"} value={"mentor"}/>
                <Radio label={"Student"} value={"student"}/>
            </Radio.Group>
            <Space h={"md"}/>

            <TextInput label={"Name"} value={newName} onChange={(e) => setNewName(e.target.value)}
                       error={!newName && "Must not be empty"}
            />
            <TextInput label={"Email"} value={newEmail} onChange={(e) => setNewEmail(e.target.value)}
                       error={!newEmail && "Must not be empty"}
            />
            <TextInput label={"University"} value={newUniversity} onChange={(e) => setNewUniversity(e.target.value)}
                       error={!newUniversity && "Must not be empty"}/>
            <Space h={"md"}/>

            <TextInput label={"Username"} value={newUser} onChange={(e) => setNewUser(e.target.value)}
                       error={!newUser && "Must not be empty"}/>
            <TextInput label={"Password"} value={newPassword} onChange={(e) => setNewPassword(e.target.value)}
                       type={"password"} error={newPassword ? newPassword.length < 8 ? "Password must be 8 characters or longer" : "" : "Must not be empty"}/>
            <Space h={"md"}/>
            {
                userType === "mentor" ? <Button onClick={() => setRequireDocuments(true)}>Next</Button> :
                    <Button onClick={register}>Register</Button>
            }

        </div>
    )
}