import {TopBar} from "./TopBar";
import {Input, Loader, ScrollArea, Space} from "@mantine/core";
import {slug_to_name, subject_list, subject_list_slugs} from "../subjects";
import {Link, useParams} from "react-router-dom";
import {IconSearch} from "@tabler/icons";
import {useEffect, useState} from "react";
import {API_URL} from "../config";
import {WithBottomBar} from "./BottomBar";
import {get_mentors} from "../api/api";

export function StudentViewMentorSelect() {
    let { subject } = useParams();
    const [mentors, setMentors] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        console.log(subject_list_slugs)
        get_mentors().then(r => {
            setMentors(r.filter( e => e.subjects?.includes(subject)))
            setLoading(false)
        })
    }, [])


    return (
        <div className="app">
            <span className={"header"}>{slug_to_name(subject)}</span>
            <Space h={"md"}/>
            {!loading ? <>
                <ScrollArea style={{'height': 'fit-content'}}>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        {
                            mentors.map((v, i) => (
                                <Link key={i} to={`/profile/mentor/${v.username}`} className={"btn-main-selector"} as={"a"}>
                                    {v.name}
                                    <br/>
                                    <span style={{fontSize: "0.7em", fontWeight: "normal"}}>{v.university}</span>
                                </Link>
                            ))
                        }
                    </div>
                </ScrollArea>
                <Space h={"md"}/>
                <Input
                    size={"xl"}
                    icon={<IconSearch/>}
                    placeholder="Search"
                />
            </> : <>
                <div className={"horizontal array justified-evenly"}>
                    <Loader/>
                </div>
            </>}

            <WithBottomBar active={"mentors"}/>
        </div>
    )
}