import {Navigate, Redirect, redirect, useParams} from "react-router-dom";
import {memo, useEffect, useRef, useState} from "react";
import {get_me, get_schedule_request, get_user, get_video_chat_url} from "../api/api";


export function VideoCall() {
    let { room_id } = useParams();

    useEffect(() => {
        const me = get_me();
        window.location.href = get_video_chat_url(me.username, room_id)

    }, [])

    const [otherPerson, setOtherPerson] = useState({});
    const [loading, setLoading] = useState(false)
    const [sendingMessage, setSendingMessage] = useState(false);

    return (
        <div className="app">
            <p>Loading Call</p>
        </div>
    )
}