import {Link, useNavigate, useParams} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import {API_URL} from "../config";
import {TopBar} from "./TopBar";
import {
    Input,
    ScrollArea,
    Space,
    Card,
    Text,
    Tabs,
    Table,
    Avatar,
    Button,
    Divider,
    Modal,
    Textarea,
    Paper, Loader
} from "@mantine/core";
import {MessageBox} from "react-chat-elements";
import {
    IconArrowBackUp,
    IconCalendarTime,
    IconMessageCircle, IconMessagePlus,
    IconPhoto,
    IconQuestionMark,
    IconSend,
    IconSettings
} from "@tabler/icons";
import {WithBottomBar} from "./BottomBar";
import {storage_get_me} from "../api/storage";
import {get_schedules, get_thread, get_thread_info, post_thread} from "../api/api";
import {slug_to_name} from "../subjects";
import {ArrowForwardUp, CalendarTime} from "tabler-icons-react";
import * as PropTypes from "prop-types";

const todayTs = new Date((new Date()).toDateString()) / 1000

function Message(props) {
    if (!props.thread.from.username) {
        return (
            <Paper p={"sm"} shadow={"sm"}>
                <Loader/>
            </Paper>
        )
    }

    return <Paper p={"sm"} shadow={"sm"}>
        <div className={"horizontal array gaped-1-2"} style={{alignItems: "center"}} onClick={props.onClick}>
            <Avatar size={"lg"} radius="xl"
                    src={`https://avatars.dicebear.com/api/open-peeps/${props.thread.from.username}.svg`}/>
            <div className={"vertical array"}>
                <div className={"horizontal array gaped-1-3"} style={{alignItems: "baseline"}}>
                    <Text weight={"bold"} size={"lg"}>{props.thread.from.name}</Text>
                    <Text weight={"bold"} size={"sm"} color={"gray"}>@{props.thread.from.username}</Text>
                </div>
                <Text lineClamp={1} size={"sm"}>{props.thread.from.university}</Text>
            </div>
        </div>
        <Space h={"lg"}/>
        <Text fz={"xl"}>{props.thread.message}</Text>
        <div className={"smol-date"}>{(new Date(props.thread.posted * 1000)).toLocaleString()}</div>
    </Paper>;
}

Message.propTypes = {
    onClick: PropTypes.func,
    thread: PropTypes.arrayOf(PropTypes.any)
};

export function ThreadView() {
    let {thread_id} = useParams();
    let me = storage_get_me()
    let [thread, setThread] = useState([]);
    let [refresh, setRefresh] = useState(0);
    let [error, setError] = useState("");
    let [loading, setLoading] = useState(true);

    let [replyModal, setReplyModal] = useState(false);
    let [replyMessage, setReplyMessage] = useState("")

    let nav = useNavigate();


    useEffect(() => {
        (async () => {
            const thread_info = await get_thread_info(thread_id);
            console.log("Thread Info Data", thread_info[0])
            setThread(thread_info[0]);
            if (thread_info[0].child.length) {
                const t = await get_thread(thread_id);
                console.log("Thread data",  t[0])
                t[0].child.reverse()
                setThread(t[0])
            }
            setLoading(false)
        })()
    }, [refresh])

    if (loading) {
        return (
            <div className="app">
                <Text size={"lg"}>Loading Thread</Text>
                <WithBottomBar active={"home"}/>
            </div>
        )
    }

    const navigateToProfile = (person) => {
        console.log(person)
        nav(`/profile/mentor/${person.username}`)
    }

    const newReply = async () => {
        await post_thread(me.username, replyMessage, thread.id);
        setReplyMessage("")
        setReplyModal(false)
        setRefresh(Math.random())
    }

    return (
        <div className="app">
            <Modal
                opened={replyModal}
                onClose={() => setReplyModal(false)}
                title="New Thread"
            >
                <div className={"vertical array gaped"}>
                    <Textarea value={replyMessage} onChange={(event) => setReplyMessage(event.currentTarget.value)}/>
                    <Button onClick={newReply}>Send Reply</Button>
                </div>
            </Modal>
            <Message onClick={() => navigateToProfile(thread.from)} thread={thread}/>
            <Space h={"md"}/>
            <div className={"horizontal-reverse array justified-between"} style={{alignItems: 'center'}}>
                <Button variant={"subtle"} color={"gray"} onClick={() => setReplyModal(true)}>Reply &nbsp;<IconMessagePlus/></Button>
                <h3>Replies</h3>
            </div>
            <Space h={"md"}/>

            <ScrollArea>
                <div className={"vertical array gaped"}>
                {
                    thread.child?.map(v => <Message thread={v} onClick={() => navigateToProfile(v.from)}/>)
                }
                </div>
            </ScrollArea>
            <WithBottomBar active={"home"}/>
        </div>
    )
}