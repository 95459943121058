import {TopBar} from "./TopBar";
import {
    Alert,
    Avatar,
    Button, Divider,
    Input,
    Loader,
    Modal,
    NumberInput,
    Paper,
    ScrollArea,
    Select,
    Space,
    Text,
    TextInput
} from "@mantine/core";
import {slug_to_name, subject_list, subject_list_slugs, subject_name_to_slug} from "../subjects";
import {Link, useParams} from "react-router-dom";
import {IconChevronRight, IconSearch} from "@tabler/icons";
import {useEffect, useState} from "react";
import {API_URL} from "../config";
import {WithBottomBar} from "./BottomBar";
import {aggregate, get_mentors} from "../api/api";
import {Dropdown} from "react-chat-elements";
import {
    get_schedules_by_subject,
    get_users_attending_schedule,
    mentor_create_schedule,
    user_schedule_attendance
} from "../api/new_mentor_scheduling";
import {storage_get_me} from "../api/storage";
import {DatePicker} from "@mantine/dates";
import {AlertCircle} from "tabler-icons-react";
import {showNotification, useNotifications} from "@mantine/notifications";

function ScheduleView({schedule}) {
    const me = storage_get_me();
    const [opened, setOpened] = useState(false);
    const [isAlreadyHere, setIsAlreadyHere] = useState(true);
    const [isFull, setIsFull] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [scheduleLimit, setScheduleLimit] = useState(0);
    const [attendingCount, setAttendingCount] = useState(0);


    useEffect(() => {
        get_users_attending_schedule(schedule.id).then(users => {
            console.log(users)
            let i = users.filter(user => user.user.username === me.username)
            console.log("Is already here?", i)
            setAttendingCount(users.length)
            setScheduleLimit(schedule.limit)
            setIsAlreadyHere(!!i.length)
            setIsFull(users.length >= schedule.limit)
            setIsLoading(false)
        })
    }, [])
    const joinSchedule = () => {
        setIsLoading(true);
        user_schedule_attendance(me.username, schedule.id).then(result => {
            setIsLoading(false);
            showNotification({
                title: 'Joined Schedule',
                message: `Successfully joined ${schedule.mentor.name}'s schedule.`,
            })
            setOpened(false)
        })
    }

    if (isFull) {
        return <></>
    }

    return (
        <>
            <Modal
                opened={opened}
                onClose={() => setOpened(false)}
                withCloseButton={false}
            >
                <Text>{`Schedule Information`}</Text>
                <TextInput disabled value={slug_to_name(schedule.subject)} label={"Subject"}/>
                <TextInput value={schedule.id} label={"Schedule ID"}/>
                {
                    schedule.mentor.username === me.username &&  <>
                    <Space h={"sm"}/>
                        <Alert icon={<AlertCircle size={16}/>}>
                        You're the mentor of this schedule
                    </Alert></>
                }
                {
                    isAlreadyHere && <>
                        <Space h={"sm"}/>
                        <Alert icon={<AlertCircle size={16}/>}>
                            You're already enrolled in this schedule
                        </Alert>
                    </>
                }
                {
                    isFull && <>
                        <Space h={"sm"}/>
                        <Alert icon={<AlertCircle size={16}/>}>
                            This schedule is already full
                        </Alert>
                    </>
                }
                <TextInput value={`${attendingCount}/${scheduleLimit}`} label={"Attending"}/>
                <Space h={"md"}/>
                <div className={"footer horizontal array gaped-1-3"}>
                    <Button onClick={() => joinSchedule()} fullWidth color={'green'} disabled={
                        schedule.mentor.username === me.username || isAlreadyHere || isFull || isLoading}>I'm Interested!</Button>
                    <Button fullWidth variant={"outline"} color={'red'} onClick={() => setOpened(false)}>Cancel</Button>
                </div>
            </Modal>

            <Paper shadow="lg" p="md" onClick={() => setOpened(true)}>
                <div className={"horizontal array gaped justified-between"}>
                    {
                        isLoading ? <Loader/> : <>
                            <Avatar size={"lg"} radius="xl"
                                    src={`https://avatars.dicebear.com/api/open-peeps/${schedule.mentor.username}.svg`}/>
                            <div className={"vertical array gaped-1-4"}>
                                <span><b>{schedule.mentor.name}</b></span>
                                <span className={"sub-text"}>{schedule.mentor.university}</span>
                                <span className={"sub-text"}>{slug_to_name(schedule.subject)}</span>
                            </div>
                            <div className={"vertical array"}>
                                {
                                    schedule.schedule.map(v => <div className={"sub-text"}>{`${(new Date(v * 1000)).toLocaleDateString()}`}</div>)
                                }
                            </div>
                        </>
                    }
                </div>
            </Paper>
        </>
    );
}


export function Search() {
    const [subject, setSubject] = useState("")
    const [schedules, setSchedules] = useState([]);
    const [filteredSchedules, setFilteredSchedules] = useState([]);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        if (!subject) {
            return
        }
        setLoading(true)

        get_schedules_by_subject(subject_name_to_slug(subject)).then(resultSchedules => {
            console.log("Schedules by subject", subject, resultSchedules);
            setSchedules(resultSchedules);
            setLoading(false);
        })

    }, [subject])

    useEffect(() => {
        if (!subject) {
            return
        }

        fetch(`${API_URL}/push?collection=search-metrics&upsert=true`, {
            method: 'POST',
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'filter': {
                    'subject': subject_name_to_slug(subject)
                },
                'update': { $inc: { views: 1 }}
            })
        })
    }, [subject])

    useEffect(() => {
        setFilteredSchedules(schedules.filter(v => {
            let ok = true
            if (search !== "") {
                ok = JSON.stringify(v).toLowerCase().includes(search.toLowerCase())
            }
            console.log("Filtering schedule", v)
            ok = v.schedule[v.schedule.length - 1] * 1000 > Date.now()
            return ok
        }))
    }, [schedules, search])

    const showInterest = () => {

    }

    return (
        <div className="app">
            <Select size={"lg"}
                    label={"Subject"}
                    value={subject}
                    searchable
                    nothingFound="No options"
                    data={subject_list}
                    onChange={v => {
                        setSubject(v)
                    }
                    }/>
            <Space h={"sm"}/>
            <TextInput
                size={"lg"}
                icon={<IconSearch/>}
                placeholder="Search"
                value={search}
                onChange={v => setSearch(v.target.value)}
            />
            <Space h={"md"}/>
            {!loading ? <>
                <ScrollArea style={{'height': 'fit-content'}}>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        {
                            filteredSchedules.map(sched => <>
                                <ScheduleView schedule={sched}/>
                                <Space h={"xs"}/>
                            </>)
                        }
                    </div>
                </ScrollArea>

            </> : <>
                <div className={"horizontal array justified-evenly"}>
                    <Loader/>
                </div>
            </>}

            <WithBottomBar active={"search"}/>
        </div>
    )
}