import {WithBottomBar} from "./BottomBar";
import {Image, Space} from "@mantine/core";
import {storage_get_me} from "../api/storage";
import {useEffect, useState} from "react";
import {IconChevronRight} from "@tabler/icons";
import {useNavigate} from "react-router-dom";
import {MentorCheckNotification} from "./MentorCheckNotification";


export function NativeButton({onClick, label}) {
    return <div className={"native-button"} onClick={onClick}>
        <div className={"horizontal array justified-between"}>
            <span>{label}</span>
            <IconChevronRight/>
        </div>
    </div>
}

export function AccountSettings() {
    let me = storage_get_me()
    let [schedules, setSchedules] = useState([]);
    let [upNext, setUpNext] = useState();
    let nav = useNavigate();

    useEffect(() => {

    }, [])

    const logout = () => {
        localStorage.clear()
        nav("/login")
    }

    return (
        <div className="app">
            <h1>Hey {me.name}!</h1>
            <MentorCheckNotification/>
            <div className={"vertical array padded-1-1"} style={{height: "100%"}}>
                <div className={"horizontal array padded-1-2"}>
                    <Image src={`https://avatars.dicebear.com/api/open-peeps/${me.username}.svg`}
                        height={100} radius={"xl"}
                    />
                    <p>
                        Lorem ipsum whatever goes in here
                    </p>
                </div>
                <Space h={"lg"}/>
                <div className={"vertical array padded-1-4 gaped-1-2"} style={{height: "100%"}}>
                    <span className={"label"}>Settings</span>
                    <NativeButton label={"Edit Profile"}/>
                    {
                        me.type === "mentor" && <NativeButton label={"Set Subjects"} onClick={() => nav("/settings/subjects")}/>
                    }
                    {
                        me.admin && <NativeButton label={"Admin"} onClick={() => nav("/admin")}/>
                    }
                    <div style={{height: '100%'}}><span>&nbsp;</span></div>
                    <NativeButton label={"Sign Out"} onClick={logout}/>
                </div>
            </div>
            <WithBottomBar active={"settings"}>
            </WithBottomBar>
        </div>
    )
}