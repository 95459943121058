export const subject_list = [
    "Organization and Management Concepts",
    "Introduction to Computing",
    "Computer Programming 1 & 2",
    "Fundamentals of Information System",
    "Web Development",
    "Living in IT Era",
    "Data Structures and Algorithm",
    "IT Infrastructure and Network Technologies",
    "Fundamentals of Business Analytics",
    "Information Management",
    "System Analysis and Design",
    "Fudamentals of Enterprise Data Management",
    "Human Computer Interaction",
    "Technopreneurship",
    "Applications Development and Emerging Technologies",
    "Methods of Research in Computing",
    "Enterprise Architecture",
    "IS Project Management",
    "IS Innovations and New Technologies",
    "Fundamentals of Analytics Modeling",
    "Data Mining",
    "Business Process Management",
    "Quantitative Methods",
    "Strategy Management and Acquisition",
    "Professional Isuues in Information System",
    "Enterprise Systems",
    "Thesis Writing",
    "Analytics Techniques & Tools",
    "Software Engineering",
    "Information Assurance and Security",
    "Social and Professional Issues in ICT",
    "System Administration and Maintenance",
    "Network Administration and Operations",
    "Network Vulnerability Analysis and Control",
    "Systems Integration and Architecture",
    "Networking",
    "Networking and Telecommunications Management",
    "Integrative Programming and Technologies",
    "Web Systems and Technologies",
    "Platform Technologies",
    "Object Oriented Programming",
    "Fundamentals of Multimedia Systems",
    "Fundamentals of Database Systems",
    "Discrete Structures",
    "Probability and Statistics",
]
export const subject_list_slugs = subject_list.map(v => v.toLowerCase().replaceAll(" ", "-"))
export const slug_to_name = (slug) => {
    return subject_list[subject_list_slugs.indexOf(slug)]
}

export const subject_name_to_slug = name => {
    return subject_list_slugs[subject_list.indexOf(name)]
}