import {useRef} from "react";
import {useNavigate} from "react-router-dom";
import {Button, Modal, Space, TextInput} from "@mantine/core";

export function NewChat({opened, setOpened}) {
    let r = useRef();
    const nav = useNavigate()

    const newMessage = () => {
        console.log(r.current.value)
        nav(`/chat/${r.current.value}`)
    }

    return (
        <Modal
            opened={opened}
            onClose={() => setOpened(false)}
            title="New Chat"
        >
            {/* Modal content */}
            <TextInput
                ref={r}
                placeholder="@Zark_Muckerberg"
                label="Recipient"
                withAsterisk
            />
            <Space h={'sm'}/>
            <Button onClick={newMessage}>Send Message</Button>
        </Modal>
    );
}