import {Link, useNavigate, useParams} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import {API_URL} from "../config";
import {TopBar} from "./TopBar";
import {Input, ScrollArea, Space, Card, Text, Tabs, Table, Paper, Avatar, Button, Loader} from "@mantine/core";
import {MessageBox} from "react-chat-elements";
import {
    IconActivity,
    IconArrowBackUp,
    IconCalendarTime,
    IconMessageCircle,
    IconPhoto,
    IconQuestionMark,
    IconSend,
    IconSettings, IconUser, IconUsers
} from "@tabler/icons";
import {WithBottomBar} from "./BottomBar";
import {storage_get_me} from "../api/storage";
import {aggregate, approve_mentor, download_file, get_schedules, query} from "../api/api";

const todayTs = new Date((new Date()).toDateString()) / 1000


export function Admin() {
    let {user} = useParams();
    let me = storage_get_me()
    let [schedules, setSchedules] = useState([]);
    let [refresh, setRefresh] = useState(0);
    let [error, setError] = useState("");
    let [loading, setLoading] = useState(false);
    let [pendingMentors, setPendingMentors] = useState([]);
    let [users, setUsers] = useState([]);

    const nav = useNavigate();

    const loadSchedules = (async () => {
        const r = await aggregate("mentor_schedules", [
            {
                '$project': {
                    '_id': 0
                }
            }
        ])
        console.log("schedules", r)
        setSchedules(r)
    })

    const loadPendingMentors = (async () => {
        const r = await aggregate("users", [
            {
                '$match': {
                    'type': 'mentor',
                    '$or': [
                        {
                            'approved': {
                                '$exists': false
                            }
                        }, {
                            'approved': false
                        }
                    ]
                }
            }, {
                '$lookup': {
                    'from': 'documents',
                    'localField': 'username',
                    'foreignField': 'user',
                    'as': 'files'
                }
            }, {
                '$project': {
                    '_id': 0,
                    'files._id': 0,
                    'files.binary': 0
                }
            }
        ])
        console.log(r)
        setPendingMentors(r)
    })
    const loadUsers = (async () => {
        const r = await aggregate("users", [
            {
                '$lookup': {
                    'from': 'documents',
                    'localField': 'username',
                    'foreignField': 'user',
                    'as': 'files'
                }
            }, {
                '$project': {
                    '_id': 0,
                    'files._id': 0,
                    'files.binary': 0
                }
            }
        ])
        console.log(r)
        setUsers(r)
    })
    useEffect(() => {
        (async () => {
            setLoading(true)
            await loadPendingMentors()
            await loadUsers()
            await loadSchedules()
            setLoading(false)
        })()
    }, [refresh])

    const approve = async (mentor) => {
        setLoading(true)
        await approve_mentor(mentor.username)
        setRefresh(Math.random())
    }

    const navigateToProfile = (person) => {
        nav(`/profile/mentor/${person}`)
    }

    const stillHasFutureSchedule = (schedules) => {
        return !!schedules.filter(v => v * 1000 > Date.now()).length
    }

    return (
        <div className="app">
            <h1>Admin</h1>
            <ScrollArea>
                {loading && <Loader/>}
            <Tabs defaultValue="pending_mentors">
                <Tabs.List>
                    <Tabs.Tab value="metrics" icon={<IconActivity size={14} />}>Metrics</Tabs.Tab>
                    <Tabs.Tab value="pending_mentors" icon={<IconQuestionMark size={14} />}>Pending Mentors</Tabs.Tab>
                    <Tabs.Tab value="users" icon={<IconUsers size={14} />}>Users</Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel value="pending_mentors" pt="xs">
                    <div className={"vertical array gaped-1-2"}>
                        {
                            pendingMentors.length !== 0 && pendingMentors.map(t => <Paper shadow="lg" p="md">
                            <div className={"vertical array gaped-1-4 justified-between"} style={{height: "100%"}}>
                                <div>
                                    <div className={"horizontal array gaped"}>
                                        <Avatar size={"sm"} radius="xl"
                                                src={`https://avatars.dicebear.com/api/open-peeps/${t.username}.svg`}/>
                                        <div><b>{t.name}</b><br/>
                                            {t.university}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Space h={"sm"}/>
                            {t.files.length > 0 ? <b>Files</b> : <b>No Document Uploaded</b>}
                            <Space h={"sm"}/>
                            <div className={"vertical array gaped-1-2"}>
                                {
                                    t.files.map(file => <Button onClick={() => download_file(file.id)}>{file.filename}</Button>)
                                }
                            </div>
                            <Space h={"md"}/>
                            <div className={"horizontal justified-between array gaped-1-4"}>
                                <Button variant={"outline"} onClick={() => navigateToProfile(t.username)}>Profile</Button>
                                <Button color={'green'} onClick={() => approve(t)}>Approve</Button>
                            </div>
                            </Paper>)
                        }
                    </div>
                </Tabs.Panel>

                <Tabs.Panel value="users" pt="xs">
                    <div className={"vertical array gaped-1-2"}>
                    {
                        users.length !== 0 && users.map(t => <Paper shadow="lg" p="md">
                            <div className={"vertical array gaped-1-4 justified-between"} style={{height: "100%"}}>
                                <div>
                                    <div className={"horizontal array gaped"}>
                                        <Avatar size={"sm"} radius="xl"
                                                src={`https://avatars.dicebear.com/api/open-peeps/${t.username}.svg`}/>
                                        <div><b>{t.name}</b><br/>
                                            {t.university}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Space h={"md"}/>
                            <div className={"horizontal justified-between array gaped-1-4"}>
                                <Button variant={"outline"} onClick={() => navigateToProfile(t.username)}>Profile</Button>
                            </div>
                        </Paper>)
                    }
                    </div>
                </Tabs.Panel>

                <Tabs.Panel value={"metrics"} pt={"xs"}>
                    <div className={"vertical array gaped-1-2"}>
                        <span>Total Users</span>
                        <span className={"header"}>{users.length}</span>
                        <Space h={"sm"}/>
                        <span>Pending Mentors</span>
                        <span className={"header"}>{pendingMentors.length}</span>
                        <Space h={"sm"}/>
                        <span>Mentors</span>
                        <span className={"header"}>{users.filter(v => v.type === "mentor").length}</span>
                        <Space h={"sm"}/>
                        <span>Students</span>
                        <span className={"header"}>{users.filter(v => v.type === "student").length}</span>
                        <Space h={"sm"}/>
                        <span>All Time Schedule Count</span>
                        <span className={"header"}>{schedules.length}</span>
                        <Space h={"sm"}/>
                        <span>Ongoing Schedules</span>
                        <span className={"header"}>{schedules.filter(v => stillHasFutureSchedule(v.schedule)).length}</span>
                    </div>
                </Tabs.Panel>
            </Tabs>
            </ScrollArea>
            <WithBottomBar active={"settings"}/>
        </div>
    )
}