import {Link, useNavigate, useParams} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import {API_URL} from "../config";
import {TopBar} from "./TopBar";
import {
    Input,
    ScrollArea,
    Space,
    Card,
    Text,
    Tabs,
    Table,
    Button,
    Modal,
    TextInput,
    Divider,
    Select, NumberInput, Avatar, Chip, Paper
} from "@mantine/core";
import {Dropdown, MessageBox} from "react-chat-elements";
import {
    IconArrowBackUp, IconCalendar,
    IconCalendarTime, IconCamera,
    IconMessageCircle,
    IconPhoto,
    IconQuestionMark,
    IconSend,
    IconSettings, IconVideo
} from "@tabler/icons";
import {WithBottomBar} from "./BottomBar";
import {storage_get_me} from "../api/storage";
import {create_schedule_request, get_me, get_schedules, get_video_chat_url, send_message} from "../api/api";
import {slug_to_name, subject_list, subject_list_slugs} from "../subjects";
import {ArrowForwardUp, CalendarTime} from "tabler-icons-react";
import {showNotification} from "@mantine/notifications";
import {DatePicker, TimeInput} from "@mantine/dates";
import {
    delete_schedule,
    get_schedules_by_mentor,
    get_schedules_by_user,
    get_users_attending_schedule, leave_schedule_attendance,
    mentor_create_schedule
} from "../api/new_mentor_scheduling";

const todayTs = new Date((new Date()).toDateString()) / 1000

function MentorCreateSchedule() {
    const me = storage_get_me();
    const [opened, setOpened] = useState(false);
    const [dates, setDates] = useState([]);
    const [picker, setPicker] = useState();
    const [subject, setSubject] = useState("");
    const [limit, setLimit] = useState(1);
    const [creating, setCreating] = useState(false);
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 1);

    const handleNewDate = (date) => {
        if (date === null) {
            return
        }
        setTimeout(() => {setPicker(null); document.getElementById("picker").value = null}, 300)
        setDates((v) => [...dates, date])
        console.log([...dates, date])
    }

    const removeDate = index => {
        dates.splice(index, 1)
        setDates([...dates])
    }

    const submitRequest = () => {
        setCreating(true)
        mentor_create_schedule(me.username, subject, dates, limit).then(v => {
            const [id, r] = v
            console.log(id, r)
            window.location.reload()
        })
    }

    return (
        <>
            <Modal
                opened={opened}
                onClose={() => setOpened(false)}
                withCloseButton={false}
            >
                <Text>{`Create a schedule`}</Text>
                <Select label={"Select a Subject"} data={me.subjects.map((sl, index) => {
                    return {
                        label: slug_to_name(sl),
                        value: sl,
                    }
                })} onChange={v => setSubject(v)}/>
                <NumberInput min={1} startValue={1} label={"Attendance Limit"} value={limit} onChange={v => setLimit(v)}/>
                {
                    dates.map((v, i) => <div key={i} className={"horizontal array justified-between"} style={{alignItems: "flex-end"}}>
                            <DatePicker value={v} label={`Session ${i + 1}`} disabled style={{width: "100%"}}/>
                            <TimeInput value={v} onChange={v => {
                                setDates(d => {
                                    d[i].setHours(v.getHours(), v.getMinutes())
                                    console.log(d)
                                    return d
                                })
                            }
                            }/>
                            <Button variant={"outline"} onClick={() => removeDate(i)}>X</Button>
                        </div>
                    )
                }
                <Space h={"sm"}/>
                <Divider/>
                <DatePicker id={"picker"}
                            value={picker}
                            placeholder="Add Dates"
                            label="Session Date"
                            onChange={handleNewDate}
                            excludeDate={d => d < currentDate || dates.map(v => v.toString()).includes(d.toString())} />
                <Space h={"sm"}/>
                <div className={"footer horizontal array gaped-1-3"}>
                    {
                        !!subject && dates.length ?
                        <Button loading={creating} fullWidth color={'green'} onClick={submitRequest} disabled={!dates.length}>Send Request</Button> : null
                    }
                    <Button fullWidth variant={"outline"} color={'red'} onClick={() => setOpened(false)}>Cancel</Button>
                </div>
            </Modal>

            <Button variant={"outline"} onClick={() => setOpened(true)}>Create Schedule</Button>
        </>
    );
}


function ViewStudentsInSchedule({schedule, attending}) {
    let nav = useNavigate();
    const [opened, setOpened] = useState(false);
    const navigateToProfile = (person) => {
        nav(`/profile/student/${person.username}`)
    }
    console.log(schedule, attending)
    return (
        <>
            <Modal
                opened={opened}
                onClose={() => setOpened(false)}
                withCloseButton={false}
            >
                <Text>{`Students in Schedule`}</Text>
                <TextInput disabled={true} label={"Subject"} value={slug_to_name(schedule.subject)}/>
                {
                    attending.length === 0 ? <><Space h={"xl"}/><Text>No attendees</Text></>: <>
                        {
                            attending.map(t => <Paper shadow="lg" p="md">
                                <div onClick={() => navigateToProfile(t.user)} className={"vertical array gaped-1-4 justified-between"} style={{height: "100%"}}>
                                    <div>
                                        <div className={"horizontal array gaped-1-3"}>
                                            <Avatar size={"sm"} radius="xl"
                                                    src={`https://avatars.dicebear.com/api/open-peeps/${t.user.username}.svg`}/>
                                            <div className={"vertical array gaped-1-4"}>
                                                <b>{t.user.name}</b>
                                                <span>{t.user.university}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Paper>)
                        }
                    </>
                }
            </Modal>
            <Button variant={"outline"} onClick={() => setOpened(true)}>Students</Button>
        </>
    );
}

function DeleteSchedule({schedule}) {
    const [opened, setOpened] = useState(false);

    const del = () => {
        console.log("Delete", schedule)
        delete_schedule(schedule.id).then(r => {
            showNotification({
                title: "Deleted!",
                message: "Schedule deleted!"
            })
        })
        setTimeout(() => {
            window.location.reload()
        }, 3000)
    }

    return (
        <>
            <Modal
                opened={opened}
                onClose={() => setOpened(false)}
                withCloseButton={false}
            >
                <Text>{`Are you sure you want to delete this schedule?`}</Text>
                <div className={"footer horizontal array gaped-1-3"}>
                    <Button fullWidth color={'green'} onClick={del}>Confirm</Button>
                    <Button fullWidth variant={"outline"} color={'red'} onClick={() => setOpened(false)}>Cancel</Button>
                </div>
            </Modal>
            <Button variant={"outline"} onClick={() => setOpened(true)} color={"red"}>Delete</Button>
        </>
    );
}

function LeaveSchedule({schedule, username}) {
    const [opened, setOpened] = useState(false);

    const del = () => {
        console.log("Leaving", schedule)
        leave_schedule_attendance(schedule.id, username).then(r => {
            showNotification({
                title: "Deleted!",
                message: "Schedule deleted!"
            })
        })
        setTimeout(() => {
            window.location.reload()
        }, 3000)
    }

    return (
        <>
            <Modal
                opened={opened}
                onClose={() => setOpened(false)}
                withCloseButton={false}
            >
                <Text>{`Are you sure you want to leave this schedule?`}</Text>
                <div className={"footer horizontal array gaped-1-3"}>
                    <Button fullWidth color={'green'} onClick={del}>Confirm</Button>
                    <Button fullWidth variant={"outline"} color={'red'} onClick={() => setOpened(false)}>Cancel</Button>
                </div>
            </Modal>
            <Button variant={"outline"} onClick={() => setOpened(true)} color={"red"}>Leave</Button>
        </>
    );
}


export function ScheduleCard({schedule, dateTime}) {
    let me = storage_get_me();
    let [attending, setAttending] = useState([]);
    const navigateToVideoChat = () => {
        window.location.href = get_video_chat_url(me.username, schedule.id)
    }
    useEffect(() => {
        console.log("Date Time", (new Date(Number(dateTime))))
        get_users_attending_schedule(schedule.id).then(users => {
            setAttending(users)
        })
    },  [])
    return (
        <Card shadow="sm" p="lg" radius="md" withBorder>
            <Card.Section withBorder inheritPadding py="xs">
                <Text weight={500}>{slug_to_name(schedule.subject)}</Text>
            </Card.Section>
            <Card.Section withBorder >
                <Table>
                        <tbody>
                        <tr>
                            <td>Mentor</td>
                            <td><Link  to={`/profile/mentor/${schedule.mentor.username}`} >{schedule.mentor.name}</Link></td>
                        </tr>
                        <tr>
                            <td>Attendance Limit</td>
                            <td>{attending.length}/{schedule.limit}</td>
                        </tr>
                        <tr>
                            <td>Time</td>
                            <td>{(new Date(Number.parseInt(dateTime * 1000))).toLocaleTimeString()}</td>
                        </tr>
                        <tr>
                            <td>Actions</td>
                            <td>
                                <div className={"horizontal array gaped-1-2"}>
                                <Button variant={"outline"} onClick={navigateToVideoChat}><IconVideo/></Button>
                                <ViewStudentsInSchedule schedule={schedule} attending={attending}/>
                                {
                                    schedule.mentor.username === me.username && <DeleteSchedule schedule={schedule}/>
                                }
                                {
                                    schedule.mentor.username !== me.username && <LeaveSchedule schedule={schedule}/>
                                }
                                </div>
                            </td>
                        </tr>
                        </tbody>
                </Table>
            </Card.Section>
        </Card>
    )
}

export function ScheduleView() {
    let {user} = useParams();
    let me = storage_get_me()
    let [schedules, setSchedules] = useState([]);
    let [refresh, setRefresh] = useState(0);
    let [error, setError] = useState("");


    useEffect(() => {
        let fn = me.type === "mentor" ? get_schedules_by_mentor : get_schedules_by_user
        fn(me.username).then(r => {
            console.log(r)
            if(r.length === 0) {
                return
            }
            if (me.type !== "mentor") {
                r = r.map(v => v.schedule)
            }
            let transformed_schedules = r.reduce((acc, val) => {
                console.log("Transformed Schedule Val", val)
                if (!val.schedule) {
                    return acc
                }
                let dates = val.schedule.map(sched => {
                    return {...val, "date": sched}
                })
                dates.map(date => {
                    if (!acc[date.date]) {
                        acc[date.date] = []
                    }
                    acc[date.date].push(date)
                })
                return acc
            }, {})

            console.log(transformed_schedules)
            setSchedules(transformed_schedules)
        }).catch(e => {
            console.log(e)
        })
    }, [refresh])

    return (
        <div className="app">
            <h1>Schedules</h1>
            <ScrollArea>
            <Tabs defaultValue="past">
                <div className={"horizontal array justified-between"}>
                    <Tabs.List>
                        <Tabs.Tab value="past" icon={<IconCalendar size={14} />}>Upcoming</Tabs.Tab>
                    </Tabs.List>
                    {
                        me.type === "mentor" && <MentorCreateSchedule/>
                    }
                </div>

                <Tabs.Panel value="past" pt="xs">
                    {
                        Object.entries(schedules).map((s, i) => {
                            const [date, schedules] = s
                            if ( ((new Date()) / 1000) - 86400 > date ) {
                                return null
                            }

                            return (
                                <div key={`date-${i}`} className={"vertical array gaped-1-3"}>
                                    <Text weight={500} className={"array padded-top-1-2"}>{(new Date(date * 1000)).toLocaleDateString()}</Text>
                                    {schedules.map((v, i) => <ScheduleCard key={`s-card-${i}`} schedule={v} dateTime={date}/>)}
                                </div>
                            )
                        })
                    }
                    <span className={"no-elem-text"}>No pending schedules</span>
                </Tabs.Panel>
            </Tabs>
                <Space h={"lg"}/>
            </ScrollArea>

            <WithBottomBar active={"schedules"}/>
        </div>
    )
}