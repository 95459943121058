import {ActionIcon} from "@mantine/core";
import {IconArrowNarrowLeft} from "@tabler/icons";

export function TopBar() {

    function back() {
        window.history.back();
    }

    return (
        <div className={"top-bar"}>
            <ActionIcon onClick={back}>
                <IconArrowNarrowLeft/>
            </ActionIcon>
        </div>
    )
}