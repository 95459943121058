import {useEffect, useState} from "react";
import {showNotification} from "@mantine/notifications";
import {Accordion, Alert, Button, Divider, Image, Radio, RadioGroup, Space, TextInput} from "@mantine/core";
import {AlertCircle} from "tabler-icons-react";
import {TopBar} from "./TopBar";
import {API_URL} from "../config";
import {Link, useNavigate} from "react-router-dom";

export function Login() {
    const [existing_user_login, setEul] = useState("")
    const [existing_user_password, setEup] = useState("")

    const nav = useNavigate();

    useEffect(() => {
        if (!!localStorage.getItem("token")) {
            nav("/home")
        }
    }, [])

    const sign_in = () => {
        fetch(`${API_URL}/login?username=${existing_user_login}&password=${existing_user_password}`).then(r => r.json()).then(val => {
            if (val.error) {
                showNotification({
                    title: 'Error',
                    message: val.error,
                })
                return
            }
            showNotification({
                title: 'Logged in',
                message: "Logged in successful",
            })
            localStorage.setItem("token", val.token)
            localStorage.setItem("username", existing_user_login)
            fetch(`${API_URL}/user/${existing_user_login}`).then(r => r.json()).then(v => {
                localStorage.setItem("me", JSON.stringify(v))
                nav("/home")
            })
        })
    }

    return (
        <div className={"app bg-cream"}>
            <Image src={"https://cdn.discordapp.com/attachments/933662799899549760/1042809164172365844/S_4.png"}/>
            <Space h={"md"}/>
            <div className={"vertical array padded-1-1"}>
                <TextInput label={"Username"} value={existing_user_login} onChange={(e) => setEul(e.target.value)}/>
                <TextInput label={"Password"} value={existing_user_password}
                           onChange={(e) => setEup(e.target.value)} type={"password"}/>

                <Space h={"md"}/>
                <Button onClick={sign_in} className={"default-button"}> Login </Button>
                <Space h={"md"}/>
                <div className={"vertical array gaped-1-2 centered"}>
                    <span>Forgot your password?</span>
                    <Link to={"/register"} className={"bold app-link"}>Sign Up</Link>
                </div>
            </div>

        </div>
    )
}