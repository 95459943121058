import {TopBar} from "./TopBar";
import {Input, ScrollArea, Space} from "@mantine/core";
import {subject_list, subject_list_slugs} from "../subjects";
import {Link} from "react-router-dom";
import {IconSearch} from "@tabler/icons";

export function StudentView() {
    return (
        <div className="app">
            <span className={"header"}>What are you looking to learn?</span>
            <Space h={"md"}/>
            <ScrollArea style={{'height': '70vh'}}>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    {
                        subject_list.map((v, index) => (
                            <Link to={`/looking-for/mentor/${subject_list_slugs[index]}`}
                                  className={"btn-main-selector"} as={"a"}>
                                {v}
                            </Link>
                        ))
                    }
                </div>
            </ScrollArea>
            <Space h={"md"}/>
            <Input
                size={"xl"}
                icon={<IconSearch/>}
                placeholder="Search"
            />
        </div>
    )
}