import {API_URL} from "../config";
import {aggregate} from "./api";

export async function mentor_create_schedule(mentor, subject, schedule, limit) {
    const id = Math.floor(Math.random() * 1000000000000000000).toString(32)
    const r = await (await fetch(`${API_URL}/push?collection=mentor_schedules&upsert=true`, {
        method: 'POST',
        headers: {
            'accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            'filter': {
                'id': id
            },
            'update': {
                '$set': {
                    'id': id,
                    'limit': limit,
                    'mentor': mentor,
                    'subject': subject,
                    'approved': true,
                    'schedule': schedule.map(v => v / 1000)
                }
            }
        })
    })).json()
    return [id, r]
}

export async function user_schedule_attendance(user, schedule) {
    const id = Math.floor(Math.random() * 1000000000000000000).toString(32)
    const r = await (await fetch(`${API_URL}/push?collection=mentor_schedule_attendance&upsert=true`, {
        method: 'POST',
        headers: {
            'accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            'filter': {
                'id': id
            },
            'update': {
                '$set': {
                    'id': id,
                    'schedule': schedule,
                    'user': user,
                    'approved': false,
                }
            }
        })
    })).json()
    return [id, r]
}

export async function delete_schedule(schedule_id) {
    const r = await (await fetch(`${API_URL}/push?collection=mentor_schedules&upsert=true`, {
        method: 'POST',
        headers: {
            'accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            'filter': {
                'id': schedule_id
            },
            'update': {
                '$set': {
                    'id': "DELETED",
                    'user': "DELETED",
                    'schedule': [],
                    'approved': false,
                    'subject': "DELETED"
                }
            }
        })
    })).json()
    return [schedule_id, r]
}


export async function leave_schedule_attendance(schedule_id, user) {
    const r = await (await fetch(`${API_URL}/push?collection=mentor_schedule_attendance&upsert=true`, {
        method: 'POST',
        headers: {
            'accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            'filter': {
                'schedule': schedule_id,
                'user': user
            },
            'update': {
                '$set': {
                    'id': "DELETED",
                    'user': "DELETED",
                    'schedule': "DELETED"
                }
            }
        })
    })).json()
    return r
}

export async function get_users_attending_schedule(schedule) {
    return await aggregate("mentor_schedule_attendance", [
        {
            '$match':
                {
                    'schedule': schedule
                }

        },
        {
            '$lookup': {
                'from': 'users',
                'localField': 'user',
                'foreignField': 'username',
                'as': 'user'
            }
        }, {
            '$set': {
                'user': {
                    '$arrayElemAt': [
                        '$user', 0
                    ]
                }
            }
        }, {
            '$project': {
                '_id': 0,
                'user._id': 0
            }
        }
    ])
}

export async function get_schedules_by_mentor(mentor) {
    return await aggregate("mentor_schedules", [
        {
            '$match':
                {
                    'mentor': mentor
                }

        }, {
            '$lookup': {
                'from': 'users',
                'localField': 'mentor',
                'foreignField': 'username',
                'as': 'mentor'
            }
        }, {
            '$set': {
                'mentor': {
                    '$arrayElemAt': [
                        '$mentor', 0
                    ]
                }
            }
        }, {
            '$project': {
                '_id': 0,
                'mentor._id': 0
            }
        }
    ])
}

export async function get_schedules_by_subject(subject) {
    return await aggregate("mentor_schedules", [
        {
            '$match':
                {
                    'subject': subject
                }

        }, {
            '$lookup': {
                'from': 'users',
                'localField': 'mentor',
                'foreignField': 'username',
                'as': 'mentor'
            }
        }, {
            '$set': {
                'mentor': {
                    '$arrayElemAt': [
                        '$mentor', 0
                    ]
                }
            }
        }, {
            '$project': {
                '_id': 0,
                'mentor._id': 0
            }
        }
    ])
}


export async function get_schedules_by_user(user) {
    return await  aggregate("mentor_schedule_attendance", [
        {
            '$match': {
                'user': user
            }
        }, {
            '$lookup': {
                'from': 'users',
                'localField': 'user',
                'foreignField': 'username',
                'as': 'user'
            }
        }, {
            '$lookup': {
                'from': 'mentor_schedules',
                'localField': 'schedule',
                'foreignField': 'id',
                'as': 'schedule'
            }
        }, {
            '$set': {
                'user': {
                    '$arrayElemAt': [
                        '$user', 0
                    ]
                },
                'schedule': {
                    '$arrayElemAt': [
                        '$schedule', 0
                    ]
                }
            }
        }, {
            '$lookup': {
                'from': 'users',
                'localField': 'schedule.mentor',
                'foreignField': 'username',
                'as': 'schedule.mentor'
            }
        }, {
            '$set': {
                'schedule.mentor': {
                    '$arrayElemAt': [
                        '$schedule.mentor', 0
                    ]
                }
            }
        }, {
            '$project': {
                '_id': 0,
                'user._id': 0,
                'schedule._id': 0,
                'schedule.mentor._id': 0
            }
        }
    ])
}