import {useNavigate, useParams} from "react-router-dom";
import {memo, useEffect, useRef, useState} from "react";
import {API_URL} from "../config";
import {Avatar, Card, Chip, Input, Loader, ScrollArea, Space, Text, Button} from "@mantine/core";
import {MessageBox} from "react-chat-elements";
import {IconSend} from "@tabler/icons";
import {get_schedule_request, get_user} from "../api/api";
import {slug_to_name} from "../subjects";
import {storage_get_me} from "../api/storage";
import {ViewSchedule} from "./ViewSchedule";

function ScheduleRequestUnwrapped({request_slug}) {
    const [header, request_id] = request_slug.split("\n")[0].split(":")
    const me = storage_get_me()
    const [request, setRequest] = useState({})
    const [student, setStudent] = useState({})
    const [mentor, setMentor] = useState({})
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            setLoading(true)
            try {
                let r = await get_schedule_request(request_id)
                console.log(r)

                let s = await get_user(r.user)
                console.log(s)

                let m = await get_user(r.mentor)
                console.log(m)
                setRequest(r)
                setStudent(s)
                setMentor(m)
            } catch (e) {

            } finally {
                setLoading(false)
            }

        })()

    }, [request_slug])



    return (
        <>
        <div className={"horizontal array padded-top-1-2"}>
            <Card withBorder shadow="sm" radius="md" w={"100%"} className={`${request.canceled ? 'red-shadow': request.approved ? 'green-shadow' : '' }`}>
                {loading ? <Loader/> :
                    <>
                        <Card.Section inheritPadding py="xs">
                            {request.canceled ? <Chip checked={false} color={'red'}>Canceled</Chip> : request.approved && <Chip checked={true} color={'green'}>Approved</Chip>}
                            <Text weight={500}>Mentor Request from {student.name} on {slug_to_name(request.subject)}</Text>
                        </Card.Section>
                        <Space h={"md"}/>
                        <ViewSchedule request={request} student={student} me={me}/>
                    </>
                }
            </Card>
        </div>
            <Space h={'sm'}/>
        </>
    )
}

const ScheduleRequest = memo(ScheduleRequestUnwrapped)

export function ChatView() {
    let {user} = useParams();
    let [messages, setMessages] = useState([]);
    let [textBox, setTextBox] = useState("");
    let [refresh, setRefresh] = useState(0);
    let [alrScroll, setAlrScroll] = useState(false);
    let bottom = useRef();
    const me = localStorage.getItem("username");
    const nav = useNavigate();

    const [error, setError] = useState("");

    const [otherPerson, setOtherPerson] = useState({});
    const [loading, setLoading] = useState(false)
    const [sendingMessage, setSendingMessage] = useState(false);

    useEffect(() => {
        setLoading(true)
        get_user(user).then(r => {
            if (!r) {
                setError("User not found")
            }
            setOtherPerson(r) //.filter( e => e.subjects?.includes(subject))
            setLoading(false)
        })
    }, [user])

    useEffect(() => {
        const i = setInterval(() => {
            setRefresh(Math.random());
        }, 1000)

        return () => {
            clearInterval(i);
        }
    }, []);

    useEffect(() => {
        const me = localStorage.getItem("username")
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(`${API_URL}/messages/query?u1=${me}&u2=${user}&page=1`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.length !== messages.length) {
                    setAlrScroll(false);
                }
                setMessages(result)
            })
            .catch(error => console.log('error', error));
    }, [refresh])

    useEffect(() => {
        if (messages.length === 0) {
            return;
        }
        if (alrScroll === true) {
            return;
        }

        bottom.current?.scrollIntoView()
        setAlrScroll(true);
    }, [messages])

    const sendMessage = (e) => {
        e.preventDefault()
        setSendingMessage(true);
        console.log("Sending", textBox);
        setTextBox("");

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "fr": me,
            "to": user,
            "contents": textBox,
            "timestamp": 0
        })

        var requestOptions = {
            method: 'POST',
            body: raw,
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${API_URL}/messages/send`, requestOptions)
            .then(response => response.text())
            .then(result => {
                console.log(result);
                setRefresh(Math.random());
                setAlrScroll(false);
                setSendingMessage(false);
            })
            .catch(error => console.log('error', error));
    }

    const navigateToProfile = () => {
        nav(`/profile/${otherPerson.type}/${otherPerson.username}`)
    }

    const processMessage = (v, i) => {
        if (v.contents.startsWith("@schedule_request")) {
            return (<ScheduleRequest key={i} request_slug={v.contents}/>)
        }

        return (
            <MessageBox key={i}
                position={v.fr === user ? "left" : "right"}
                title={v.fr}
                type='text'
                text={v.contents}
                date={new Date(v.timestamp * 1000)}
                replyButton={false}
            />
        )
    }

    if (error) {
        return (
            <div className="app">
                <Space h={"xl"}/>
                <div className={"horizontal array justified-evenly"}>
                    <span>Error: {error}</span>
                </div>
                <Space h={"md"}/>
                <Button onClick={() => window.history.back()}>Go Back</Button>
            </div>
        )
    }

    return (
        <div className="app">
            <div className={"horizontal array gaped-1-2"} style={{alignItems: "center"}} onClick={navigateToProfile}>
                <Avatar size={"lg"} radius="xl" src={`https://avatars.dicebear.com/api/open-peeps/${otherPerson.username}.svg`}/>
                <div className={"vertical array"}>
                    <div className={"horizontal array gaped-1-3"} style={{alignItems: "baseline"}}>
                        <Text weight={"bold"} size={"lg"}>{otherPerson.name}</Text>
                        <Text weight={"bold"} size={"sm"} color={"gray"}>@{otherPerson.username}</Text>
                    </div>
                    <Text lineClamp={1} size={"sm"}>{otherPerson.university}</Text>
                </div>
            </div>
            <Space h={"sm"}/>
            {
                loading ? <div className={"horizontal array justified-between"}>
                    <Loader/>
                </div> :
                    <>
                        <ScrollArea style={{height: "100%"}}>
                            {
                                messages.map((v, i) => processMessage(v, i))
                            }
                            <div ref={bottom}></div>
                        </ScrollArea>
                        <Space h={"sm"}/>
                        <form onSubmit={sendMessage}>
                            <Input
                                value={textBox}
                                onChange={e => setTextBox(e.target.value)}
                                size={"lg"}
                                rightSection={<button type="submit">{sendingMessage ? <Loader size={'sm'}/> : <IconSend/>}</button>}
                            />
                        </form>
                    </>
            }

        </div>
    )
}