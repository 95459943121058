import {storage_get_me} from "../api/storage";
import {Alert, Space} from "@mantine/core";
import {AlertCircle} from "tabler-icons-react";

export function MentorCheckNotification() {
    let me = storage_get_me()
    return (!me.approved && me.type === "mentor") && <Alert icon={<AlertCircle size={16}/>}>
        You're pending verification from Stuney Admins
        <Space h={"sm"}/>
    </Alert>;
}