import {useState} from "react";
import {approve_schedule_request} from "../api/api";
import {showNotification} from "@mantine/notifications";
import {Button, Divider, Modal, Space, Text, TextInput} from "@mantine/core";
import {slug_to_name} from "../subjects";
import {DatePicker} from "@mantine/dates";

export function ViewSchedule({request, student, me}) {
    const [opened, setOpened] = useState(false);
    const [loading, setLoading] = useState(false);

    async function approveSchedule() {
        try {
            setLoading(true)
            await approve_schedule_request(request.id)
            showNotification({
                title: "Success",
                message: `Request Approved!`
            })
            setOpened(false)
        } catch (e) {
            showNotification({
                title: "Error",
                message: `Error: ${e.toString()}`
            })
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            <Modal
                opened={opened}
                onClose={() => setOpened(false)}
                withCloseButton={false}
            >
                <Text>{`Schedule with ${student.name}`}</Text>
                <TextInput label="Subject" value={slug_to_name(request.subject)} disabled/>
                {
                    request.schedule?.map((v, i) => <div key={i} className={"horizontal array justified-between"}
                                                         style={{alignItems: "flex-end"}}>
                            <DatePicker value={(new Date(v * 1000))} label={`Session ${i + 1}`} disabled
                                        style={{width: "100%"}}/>
                        </div>
                    )
                }
                <Space h={"sm"}/>
                <Divider/>
                <Space h={"sm"}/>
                <div className={"footer horizontal array gaped-1-3"}>
                    {
                        (!request.approved && request.user !== me.username) &&
                        <Button fullWidth color={'green'} onClick={approveSchedule} loading={loading}>Approve</Button>
                    }
                    {
                        (request.approved || !request.canceled) && <Button fullWidth color={'red'}>Cancel</Button>
                    }
                    <Button fullWidth variant={"outline"} color={'red'} onClick={() => setOpened(false)}>Close</Button>
                </div>
            </Modal>

            <Button fullWidth variant={"outline"} onClick={() => setOpened(true)} disabled={request.canceled}>
                {request.canceled ? 'Request Canceled' : "View Details"}
            </Button>
        </>
    );
}