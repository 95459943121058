import {TopBar} from "./TopBar";
import {
    Alert,
    Avatar,
    BackgroundImage,
    Button,
    Card, Chip, Divider,
    Group,
    Image,
    Input,
    Loader, Modal, Rating,
    ScrollArea,
    Space,
    Text, TextInput,
} from "@mantine/core";
import {slug_to_name, subject_list, subject_list_slugs} from "../subjects";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import {WithBottomBar} from "./BottomBar";
import {
    create_schedule_request,
    get_me,
    get_user,
    get_mentors,
    send_message,
    get_total_rating,
    get_single_rating, set_rating
} from "../api/api";
import {DatePicker} from "@mantine/dates";
import {showNotification} from "@mantine/notifications";
import { storage_get_me} from "../api/storage";
import {AlertCircle} from "tabler-icons-react";

function NavigateToSubject({subject}) {
    const me = storage_get_me();
    const nav = useNavigate();
    return (
        <>
            <Button variant={"outline"} onClick={() => nav(`/looking-for/mentor/${subject}`)}>{slug_to_name(subject)}</Button>
        </>
    );
}


export function MentorProfile() {
    let { mentor } = useParams();
    let me = get_me();
    const [loading, setLoading] = useState(true);
    const [mentorData, setMentor] = useState([]);
    const [rating, setRating] = useState({});
    const [personalRating, setPersonalRating] = useState(undefined);
    const [ratingModalOpened, setRatingModalOpened] = useState(false);

    const [refresh, setRefresh] = useState(0);

    const nav = useNavigate();

    useEffect(() => {
        (async () => {
            setLoading(true)
            if (!mentor) {
                return
            }
            let user = await get_user(mentor)
            console.log("Data", user)
            setMentor(user)

            let rating = await get_total_rating(user.username)
            console.log("Rating", rating[0])
            setRating(rating[0])

            let personalRating = await get_single_rating(me.username, user.username)
            console.log("Personal Rating", personalRating[0])
            setPersonalRating(personalRating[0])

            setLoading(false)
        })()

    }, [mentor, refresh])

    const openChat = () => {
        nav(`/chat/${mentorData.username}`)
    }

    const ratePerson = (v) => {
        console.log(v)
        setRatingModalOpened(true)
    }

    const changeRating = async (v) => {
        await set_rating(me.username, mentorData.username, v)
        setRatingModalOpened(false)
        setRefresh(Math.random())
    }

    return (
        <div className="app">
            {
                loading ?
                    <div className={"horizontal array justified-evenly"}>
                        <Loader/>
                    </div>:
                    <Card>
                        <Modal
                            opened={ratingModalOpened}
                            onClose={() => setRatingModalOpened(false)}
                            title={`Rate ${mentorData.name}`}
                        >
                            <Rating value={personalRating?.value} onChange={changeRating} />
                        </Modal>
                        <Card.Section>
                            <Image
                                src={`https://avatars.dicebear.com/api/open-peeps/${mentorData.username}.svg`}
                                height={260}
                                alt="Norway"
                            />
                        </Card.Section>
                        <div className={"vertical array gaped-1-4 padded-top-1-2"}>
                            <div className={"horizontal array justified-between"}>
                                <div className={"horizontal array gaped-1-2"} style={{alignItems: 'baseline'}}>
                                    <Text weight={500} size={"lg"}>{mentorData.name}</Text><Text weight={500} size={"xs"} color={"gray"}>{mentorData.username}</Text>
                                </div>
                                <Chip checked={!!personalRating} onChange={ratePerson}>⭐{rating && Math.floor(rating.average * 10) / 10}</Chip>
                            </div>
                            <Text>{mentorData.university}</Text>
                            {
                                mentorData.approved ? <Chip defaultChecked disabled={true}>Verified</Chip>:
                                    mentorData.type === "mentor" ?
                                    <Alert icon={<AlertCircle size={16}/>}>
                                        This mentor is pending approval from Stuney
                                    </Alert> : null
                            }
                        </div>

                        <ScrollArea.Autosize style={{height: "52vh"}}>
                            <div className={"vertical array"} style={{width: "84vw"}}>
                                <Space h={"sm"}/>
                                <Text size="sm" color="dimmed">
                                    Biography goes here.
                                </Text>
                                <Space h={"sm"}/>
                                <Button fullWidth onClick={openChat}>Send Message</Button>
                                <Space h={"sm"}/>
                                {
                                    mentorData.type === "mentor" && <>
                                        <Text size={"lg"}>Subjects Offered</Text>
                                        <div className={"vertical array gaped-1-3"}>
                                            {
                                                mentorData.subjects?.map(v => {
                                                    return <NavigateToSubject mentorData={mentorData} subject={v}/>
                                                })
                                            }
                                        </div>
                                    </>
                                }
                            </div>

                        </ScrollArea.Autosize>
                    </Card>
            }

            <WithBottomBar active={"mentors"}/>
        </div>
    )
}