import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {API_URL} from "../config";
import {NewChat} from "./NewChat";
import {TopBar} from "./TopBar";
import {SquarePlus} from "tabler-icons-react";
import {IconUser} from "@tabler/icons";
import {WithBottomBar} from "./BottomBar";
import {get_mentor} from "../api/api";
import {Avatar} from "@mantine/core";

export function ChatList() {
    const [people, setPeople] = useState([]);
    const [openNewChat, setOpenNewChat] = useState(false);
    const nav = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem("token")
        const username = localStorage.getItem("username")
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(`${API_URL}/messages/list?token=${token}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result)
                setPeople(result.filter(v => v !== username))

            })
            .catch(error => console.log('error', error));
    }, [])

    const navigateToChat = (u) => () => {
        nav(`/chat/${u}`)
    }

    return (
        <div className="app">
            <NewChat opened={openNewChat} setOpened={setOpenNewChat}/>
            <h1>Messages</h1>
            <div>
                <div className={"button-array-right"}>
                    <button onClick={() => setOpenNewChat(true)} className={"button-with-icon"}>
                        <SquarePlus/> New Thread
                    </button>
                </div>
                {
                    people.map(v => {
                            return (
                                <div className={"chat-list-item"} onClick={navigateToChat(v.username)}>
                                    <Avatar size={"lg"} radius="xl" src={`https://avatars.dicebear.com/api/open-peeps/${v.username}.svg`}/>
                                    <div style={{paddingLeft: ".5em"}} className={"vertical array"}>
                                        <b>{v.name}</b>
                                        <span>@{v.username}</span>
                                    </div>
                                </div>
                            )
                        }
                    )
                }
            </div>
            <WithBottomBar active={"messages"}/>
        </div>
    )
}