import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {TopBar} from "./TopBar";
import {Button, Checkbox, ScrollArea, Space} from "@mantine/core";
import {subject_list, subject_list_slugs} from "../subjects";
import {showNotification} from "@mantine/notifications";
import {get_me, update_me, update_user_subjects} from "../api/api";

export function MentorSubjectSelect() {
    const me = get_me();
    const [selected, setSelected] = useState(me.subjects.reduce((a, v) => {
        a[v] = true
        return a
    }, {}) || {})
    const nav = useNavigate();
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        localStorage.removeItem("mentor:selected:slugs")
        localStorage.removeItem("mentor:ongoing:register")
    }, [])

    const enable = (slug, value) => {
        let v = {...selected}
        v[slug] = value
        setSelected(v)
    }

    const proceed = async () => {
        setLoading(true)
        console.log(selected)
        let s = Object.keys(selected).filter(v => subject_list_slugs.includes(v) && selected[v])
        await update_user_subjects(me.username, s)
        showNotification({
            title: "Success",
            message: "Subjects updated!"
        })
        update_me(me.username)
        setLoading(false)
        window.history.back()
    }

    return (
        <div className="app">
            <span className={"header"}>What are you looking to teach?</span>
            <Space h={"md"}/>
            <ScrollArea style={{'height': '70vh'}}>
                {
                    subject_list.map((v, index) => (
                        <div className={"checkbox-selector"} id={`selector-${subject_list_slugs[index]}`}>
                            <Checkbox checked={selected[subject_list_slugs[index]]} id={`checkbox-${subject_list_slugs[index]}`} label={v} size="lg"
                                      onClick={(e) => {
                                          enable(subject_list_slugs[index], !selected[subject_list_slugs[index]] || false)
                                      }}/>
                        </div>
                    ))
                }
            </ScrollArea>
            <Space h={"xl"}/>
            <Button size={'xl'} onClick={proceed} loading={loading}>Update</Button>
        </div>
    )
}