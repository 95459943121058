import {TopBar} from "./TopBar";
import {Input, ScrollArea, Space} from "@mantine/core";
import {subject_list, subject_list_slugs} from "../subjects";
import {Link, useParams} from "react-router-dom";
import {IconSearch} from "@tabler/icons";
import {useEffect, useState} from "react";
import {API_URL} from "../config";
import {WithBottomBar} from "./BottomBar";
import {get_mentors} from "../api/api";

export function StudentSubjectSelect() {

    return (
        <div className="app">
            <span className={"header"}>What are you looking to learn?</span>
            <Space h={"md"}/>
            <ScrollArea style={{'height': 'fit-content'}}>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    {
                        subject_list.map((v, index) => (
                            <Link to={`/looking-for/mentor/${subject_list_slugs[index]}`}
                                  className={"btn-main-selector"} as={"a"}>
                                {v}
                            </Link>
                        ))
                    }
                </div>
            </ScrollArea>
            <WithBottomBar active={"mentors"}/>
        </div>
    )
}
