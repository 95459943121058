import {ActionIcon, Space} from "@mantine/core";
import {IconArrowNarrowLeft, IconCalendar, IconCrown, IconHome, IconMessage, IconSearch, IconUser} from "@tabler/icons";
import {useNavigate} from "react-router-dom";

export function WithBottomBar({active, children}) {
    const nav = useNavigate();
    const hash = window.location.hash

    return (
        <div className={"bottom-bar-container"}>
            <div>
                {children}
            </div>
            <div className={"bottom-bar"}>
                <button onClick={() => nav("/home")} className={active === "home" ? "active" : ""}>
                    <IconHome/>
                    Home
                </button>
                <button onClick={() => nav("/chats")} className={active === "messages" ? "active" : ""}>
                    <IconMessage/>
                    Messages
                </button>
                <button onClick={() => nav("/schedules")} className={active === "schedules" ? "active" : ""}>
                    <IconCalendar/>
                    Schedule
                </button>
                <button onClick={() => nav("/search")} className={active === "search" ? "active" : ""}>
                    <IconSearch/>
                    Search
                </button>
                <button onClick={() => nav("/student-app/mentors")} className={active === "mentors" ? "active" : ""}>
                    <IconCrown/>
                    Mentors
                </button>
                <button onClick={() => nav("/settings")}  className={active === "settings" ? "active" : ""}>
                    <IconUser/>
                    Account
                </button>
            </div>
        </div>
    )
}