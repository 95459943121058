import './App.css';
import {HashRouter, Route, Routes} from "react-router-dom";
import {Login} from "./components/Login";
import {StudentView} from "./components/StudentView";
import {MentorSubjectSelect} from "./components/MentorSubjectSelect";
import {StartView} from "./components/StartView";
import {ChatList} from "./components/ChatList";
import {ChatView} from "./components/ChatView";
import {Home} from "./components/Home";
import {TopBar} from "./components/TopBar";
import {ScheduleView} from "./components/ScheduleView";
import {StudentViewMentorSelect} from "./components/StudentViewMentorSelect";
import {StudentSubjectSelect} from "./components/SubjectSelect";
import {MentorProfile} from "./components/Profile";
import {Register} from "./components/Register";
import {VideoCall} from "./components/VideoCall";
import {AccountSettings} from "./components/AccountSettings";
import {ThreadView} from "./components/ThreadView";
import {Admin} from "./components/Admin";
import {Search} from "./components/Search";


function App() {
    return (
        <HashRouter>
            <TopBar/>
            <Routes>
                <Route path={"/"} element={<Login/>}/>
                <Route path={"/looking-for/students"} element={<MentorSubjectSelect/>}/>
                <Route path={"/looking-for/mentor"} element={<StudentView/>}/>
                <Route path={"/looking-for/mentor/:subject"} element={<StudentViewMentorSelect/>}/>
                <Route path={"/home"} element={<Home/>}/>
                <Route path={"/login"} element={<Login/>}/>
                <Route path={"/register"} element={<Register/>}/>
                <Route path={"/chat/:user"} element={<ChatView/>}></Route>
                <Route path={"/chats"} element={<ChatList/>}></Route>
                <Route path={"/call/:room_id"} element={<VideoCall/>}></Route>
                <Route path={"/schedules"} element={<ScheduleView/>}></Route>
                <Route path={"/student-app/mentors/:subject"} element={<StudentViewMentorSelect/>}></Route>
                <Route path={"/student-app/mentors"} element={<StudentSubjectSelect/>}></Route>
                <Route path={"/profile/mentor/:mentor"} element={<MentorProfile/>}></Route>
                <Route path={"/settings"} element={<AccountSettings/>}/>
                <Route path={"/settings/subjects"} element={<MentorSubjectSelect/>}/>
                <Route path={"/thread/:thread_id"} element={<ThreadView/>}/>
                <Route path={"/admin"} element={<Admin/>}/>
                <Route path={"/search"} element={<Search/>}/>
            </Routes>
        </HashRouter>
    );
}

export default App;
