import {API_URL} from "../config";

export async function update_me(username) {
    const r = await fetch(`${API_URL}/user/${username}`)
    const v = await r.json()
    localStorage.setItem("me", JSON.stringify(v))
}

export function get_me() {
    return JSON.parse(localStorage.getItem("me") || "{}")
}

export function get_video_chat_url(name, room_id) {
    return `https://video-app-8019-4650-dev.twil.io?passcode=49955480194650&name=${name}&room=${room_id}`
}

export async function query(collection, data) {
    return (await fetch(`${API_URL}/query?collection=${collection}`, {
        method: 'POST',
        headers: {
            'accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })).json()
}

export async function aggregate(collection, pipeline) {
    return (await fetch(`${API_URL}/aggregate?collection=${collection}`, {
        method: 'POST',
        headers: {
            'accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(pipeline)
    })).json()
}


export async function get_mentors() {
    return (await fetch(`${API_URL}/query?collection=users`, {
        method: 'POST',
        headers: {
            'accept': 'application/json',
            'Content-Type': 'application/json'
        },
        // body: '{"type": "mentor"}',
        body: JSON.stringify({
            'type': 'mentor'
        })
    })).json()
}


const userCache = {}

export async function get_user(username) {
    if (!!userCache[username]) {
        return {...userCache[username]}
    }
    let v = await (await fetch(`${API_URL}/query?collection=users`, {
        method: 'POST',
        headers: {
            'accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            'username': username
        })
    })).json()

    console.log(v)
    if (v?.length > 0) {
        const u = v[0]
        userCache[username] = u
        return u
    }
    return undefined
}

export async function create_schedule_request(user, mentor, subject, schedule) {
    const id = Math.floor(Math.random() * 1000000000000000000).toString(32)
    const r = await (await fetch(`${API_URL}/push?collection=schedules&upsert=true`, {
        method: 'POST',
        headers: {
            'accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            'filter': {
                'id': id
            },
            'update': {
                '$set': {
                    'id': id,
                    'user': user,
                    'mentor': mentor,
                    'subject': subject,
                    'approved': false,
                    'schedule': schedule.map(v => v / 1000)
                }
            }
        })
    })).json()
    return [id, r]
}

export async function approve_mentor(username) {
    const r = await (await fetch(`${API_URL}/push?collection=users&upsert=false`, {
        method: 'POST',
        headers: {
            'accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            'filter': {
                'username': username
            },
            'update': {
                '$set': {
                    'approved': true
                }
            }
        })
    })).json()
    return r
}

export async function approve_schedule_request(id) {
    const r = await (await fetch(`${API_URL}/push?collection=schedules&upsert=false`, {
        method: 'POST',
        headers: {
            'accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            'filter': {
                'id': id
            },
            'update': {
                '$set': {
                    'approved': true,
                }
            }
        })
    })).json()
    return [id, r]
}

export async function get_top_mentors() {
    return aggregate("ratings", [
        {
            '$sortByCount': '$to'
        }, {
            '$lookup': {
                'from': 'users',
                'localField': '_id',
                'foreignField': 'username',
                'as': 'mentor'
            }
        }, {
            '$set': {
                'mentor': {
                    '$arrayElemAt': [
                        '$mentor', 0
                    ]
                }
            }
        }, {
            '$project': {
                'mentor._id': 0
            }
        }
    ])
}

export async function post_thread(from, message, parent = null, id = null) {
    if (!id) {
        id = Math.floor(Math.random() * 1000000000000000000).toString(32)
        console.log("New ID Created for thread", id)
    }
    const r = await (await fetch(`${API_URL}/push?collection=threads&upsert=true`, {
        method: 'POST',
        headers: {
            'accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            'filter': {
                'id': id,
                'from': from,
                'parent': parent,
                'posted': ((new Date()) / 1000)
            },
            'update': {
                '$set': {
                    'message': message,
                }
            }
        })
    })).json()
    return r
}

export async function get_thread_info(thread_id) {
    return aggregate("threads", [
        {
            '$lookup': {
                'from': 'users',
                'localField': 'from',
                'foreignField': 'username',
                'as': 'from'
            }
        }, {
            '$lookup': {
                'from': 'threads',
                'localField': 'id',
                'foreignField': 'parent',
                'as': 'child'
            }
        }, {
            '$match': {
                'id': thread_id
            }
        },
        {
            '$set': {
                from: {$arrayElemAt: ["$from", 0]},
            }
        }
        , {
            '$project': {
                "_id": 0,
                "from._id": 0,
                "child._id": 0,
                "child.from._id": 0
            }
        }
    ])
}

export async function get_thread(thread_id) {
    return aggregate("threads", [
        {
            '$lookup': {
                'from': 'users',
                'localField': 'from',
                'foreignField': 'username',
                'as': 'from'
            }
        }, {
            '$lookup': {
                'from': 'threads',
                'localField': 'id',
                'foreignField': 'parent',
                'as': 'child'
            }
        }, {
            '$match': {
                'id': thread_id
            }
        }, {
            '$unwind': '$child'
        }, {
            '$lookup': {
                'from': 'users',
                'localField': 'child.from',
                'foreignField': 'username',
                'as': 'child.from'
            }
        }, {
            '$set': {
                'from': {
                    '$arrayElemAt': [
                        '$from', 0
                    ]
                },
                'child.from': {
                    '$arrayElemAt': [
                        '$child.from', 0
                    ]
                }
            }
        }, {
            '$project': {
                '_id': 0,
                'from._id': 0,
                'child._id': 0,
                'child.from._id': 0
            }
        }, {
            '$group': {
                '_id': '$_id',
                'id': {
                    '$first': '$id'
                },
                'posted': {
                    '$first': '$posted'
                },
                'from': {
                    '$first': '$from'
                },
                'message': {
                    '$first': '$message'
                },
                'child': {
                    '$push': '$child'
                }
            }
        }
    ])
}

export async function get_top_level_threads() {
    return aggregate("threads", [
        {
            '$match': {
                'parent': null
            }
        }, {
            '$lookup': {
                'from': 'users',
                'localField': 'from',
                'foreignField': 'username',
                'as': 'from'
            }
        }, {
            '$set': {
                'from': {
                    '$arrayElemAt': [
                        '$from', 0
                    ]
                }
            }
        },
        {
            '$project': {
                "_id": 0,
                "from._id": 0
            }
        },
        {
            $sort: {
                posted: -1
            }
        },
        {
            $limit: 10
        }
    ])
}

console.log(post_thread)
console.log(get_top_level_threads)

export async function upload_documents(user, filename, bin) {
    const id = Math.floor(Math.random() * 1000000000000000000).toString(32)
    const r = await (await fetch(`${API_URL}/push?collection=documents&upsert=true`, {
        method: 'POST',
        headers: {
            'accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            'filter': {
                'id': id
            },
            'update': {
                '$set': {
                    'id': id,
                    'user': user,
                    'filename': filename,
                    'binary': bin
                }
            }
        })
    })).json()
    return [id, r]
}

export async function get_user_documents(user) {
    return await aggregate("documents", [
        {
            '$match': {
                'user': user
            }
        }, {
            '$project': {
                '_id': 0,
                'binary': 0
            }
        }
    ])
}

export async function download_file(id) {
    const r = await query("documents", {"id": id})
    console.log(r)
    const a = document.createElement("a"); //Create <a>
    a.target = "_blank"
    a.href = r[0].binary; //Image Base64 Goes here
    a.download = r[0].filename; //File name Here
    a.click(); //Downloaded file
}

console.log(download_file)

export async function set_rating(from, to, value) {
    const r = await (await fetch(`${API_URL}/push?collection=ratings&upsert=true`, {
        method: 'POST',
        headers: {
            'accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            'filter': {
                'from': from,
                'to': to
            },
            'update': {
                '$set': {
                    'value': value,
                }
            }
        })
    })).json()
    return r
}

export async function get_single_rating(from, to) {
    return await query("ratings", {
        'from': from,
        'to': to
    })
}

export async function get_total_rating(user) {
    return await aggregate("ratings", [
        {
            '$match': {
                'to': user
            }
        }, {
            '$group': {
                '_id': null,
                'average': {
                    '$avg': '$value'
                },
                'total': {
                    '$sum': '$value'
                },
                'count': {
                    '$sum': 1
                }
            }
        }
    ])
}


export async function cancel_schedule_request(id) {
    const r = await (await fetch(`${API_URL}/push?collection=schedules&upsert=false`, {
        method: 'POST',
        headers: {
            'accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            'filter': {
                'id': id
            },
            'update': {
                '$set': {
                    'canceled': true,
                }
            }
        })
    })).json()
    return [id, r]
}


const TsNow = () => (new Date()) / 1000
const scheduleCache = {}

export async function get_schedule_request(request_id) {
    if (!!scheduleCache[request_id]) {
        if (TsNow() - scheduleCache[request_id].$ts < 30) {
            return {...scheduleCache[request_id]}
        }
    }
    let v = await (await fetch(`${API_URL}/query?collection=schedules`, {
        method: 'POST',
        headers: {
            'accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            'id': request_id
        })
    })).json()

    if (v?.length > 0) {
        const r = v[0]
        r.$ts = TsNow()
        scheduleCache[request_id] = r
        return r
    }
    return undefined
}


export async function get_schedules(user) {
    return await aggregate("schedules", [
        {
            '$match': {
                '$or': [
                    {
                        'user': user
                    }, {
                        'mentor': user
                    }
                ]
            }
        }, {
            '$lookup': {
                'from': 'users',
                'localField': 'user',
                'foreignField': 'username',
                'as': 'user'
            }
        }, {
            '$lookup': {
                'from': 'users',
                'localField': 'mentor',
                'foreignField': 'username',
                'as': 'mentor'
            }
        }, {
            '$set': {
                'mentor': {
                    '$arrayElemAt': [
                        '$mentor', 0
                    ]
                },
                'user': {
                    '$arrayElemAt': [
                        '$user', 0
                    ]
                }
            }
        }, {
            '$project': {
                '_id': 0,
                'mentor._id': 0,
                'user._id': 0
            }
        }
    ])
}

export async function query_users() {
    return (await fetch(`${API_URL}/query?collection=users`, {
        method: 'POST',
        headers: {
            'accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({})
    })).json()
}

export async function update_user_subjects(username, subjects) {
    return (await fetch(`${API_URL}/push?collection=users&upsert=false`, {
        method: 'POST',
        headers: {
            'accept': 'application/json',
            'Content-Type': 'application/json'
        },
        // body: '{\n  "filter": {"username": "noku_mentor"},\n  "update": {"$set": {"subjects": ["compsci-101"]}}\n}',
        body: JSON.stringify({
            'filter': {
                'username': username
            },
            'update': {
                '$set': {
                    'subjects': subjects
                }
            }
        })
    })).json()
}

export async function send_message(from, to, message) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
        "fr": from,
        "to": to,
        "contents": message,
        "timestamp": (new Date()) / 1000
    })

    var requestOptions = {
        method: 'POST',
        body: raw,
        headers: myHeaders,
        redirect: 'follow'
    };

    fetch(`${API_URL}/messages/send`, requestOptions)
}

